// Checkbox.js
import React from 'react';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? '#1CC8BB' : 'white')};
  border-radius: 3px;
  margin-right: 5px;
  transition: all 150ms;
  cursor: pointer;
  position: relative;
  border: 1px solid;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #1cc8bb;
  }

  &:after {
    content: ${(props) => (props.checked ? "'✔'" : "''")};
    position: absolute;
    left: 4px;
    top: 1px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.simpleWhite};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface Props {
  checked: boolean;
  [key: string]: any; // Include any additional props you want to pass through to the underlying input element.
}

export const Checkbox: React.FC<Props> = ({ checked, ...props }) => (
  <label>
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} />
    </CheckboxContainer>
  </label>
);
